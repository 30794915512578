import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { Link } from 'gatsby';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Carousel from '../components/Carousel';
import StudentQuote from '../components/StudentQuote';

const IndexPage = () => (
  <Layout title="Home" className="homepage">
    <div className="home-hero">
      <video
        className="home-hero__video"
        autoPlay={true}
        muted={true}
        loop={true}
        title="3M Intro Video"
        playsInline={true}
        controls={false}
        preload="auto"
      >
        <source
          src="/images/videos/hp-hero-video.mp4"
          type="video/mp4"
        ></source>
      </video>
      <div className="home-hero__branding">
        <Image filename="img-hp-hero-logos.png" className="home-hero__logos" />
        <h1 className="home-hero__pagetitle">
          Bringing science to life through curiosity and innovation.
        </h1>
      </div>
    </div>
    {/* Challenge Entry - When challenge entries are accepted */}
    <Row className="home-hero__callout-row">
      <Column offset={3} size={9}>
        <Link
          to="/annual-challenge/finalists-mentors-judges/finalists?years=2024"
          //className=" bg-gradient-orange"
          target="_blank"
        >
          <h2 className="home-hero__callout home-hero__callout-heading bg-gradient-orange">
            Introducing the Top 10 National Finalists of the 2024 3M Young
            Scientist Challenge! Meet Them Now
            <Icon name="arrowrightlarge"></Icon>
          </h2>
        </Link>
      </Column>
    </Row>
    {/* Meet Winners Banner - After voting is finished - reset link and swap h2s */}
    {/* <h2 className="home-hero__callout-heading">
          Congratulations to America’s Top Young Scientist!
          <Icon name="arrowrightlarge" />
        </h2> */}
    {/* Voting Banner - When voting starts - reset link and swap h2s  */}
    {/*<h2 className="home-hero__callout-heading">
          Improving Lives Award Public Voting Now Open! VOTE DAILY
          <Icon name="arrowrightlarge" />
         </h2>*/}
    {/* Intro Content Tryptic */}
    <Section className="intro__content mt-2 pb-0">
      <Container>
        <Row className="pr-2 pb-1">
          <h2>
            An Immersive STEM Portal, anchored by the award-winning annual 3M
            Young Scientist Challenge.
          </h2>
        </Row>
        <Row>
          <Column size={4}>
            <div className="box-shadow hover imagezoom">
              <Image filename="HomepagePromoCard-1.jpg" />
              <div className="p-2 pb-6">
                <h3>Learn about the 3M Young Scientist Challenge</h3>
                <p>
                  The annual challenge asks students in grades 5-8 to submit a
                  1–2 minute video for a chance to win $25,000 and an exclusive
                  mentorship with a 3M Scientist.
                </p>
              </div>
              <Link
                to="/annual-challenge/about-the-challenge"
                className="cta__link bg-gradient-orange-one"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="box-shadow hover imagezoom">
              <Image filename="HomepagePromoCard-2.jpg" />
              <div className="p-2 pb-6">
                <h3>Science Starters</h3>
                <p>
                  Kickstart immersive and open-ended learning experiences with
                  flexible resources designed to inspire the next generation of
                  scientists.
                </p>
              </div>
              <Link
                to="/science-starters/getting-started"
                className="cta__link bg-gradient-orange-two"
              >
                Go Now <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            {/* Challenge Close Card */}
            <div className="box-shadow hover">
              <Image filename="HomepagePromoCard-3.jpg" />
              <div className="p-2 pb-6">
                <h3>
                  Congratulations to our 2023 3M Young Scientist Challenge Winner - Named TIME's 2024 Kid of the Year!
                </h3>
              </div>
              <a
                href="https://time.com/7010714/time-and-time-for-kids-name-the-2024-kid-of-the-year-15-year-old-scientist-heman-bekele/"
                className="cta__link bg-gradient-orange-three"
                target="_blank"
              >
                Read More <Icon name="arrowright" />
              </a>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* About Young Scientist Lab */}
    <Section className="about-ysl pt-0">
      <Container>
        <Row>
          <Column size={6} className="p-2 pb-4 mt-4">
            <h2>About the Young Scientist Lab</h2>
            <p className="text-24">
              Discovery Education and 3M have partnered to inspire the next
              generation of innovators through the{' '}
              <strong>Young Scientist Lab</strong>. Through K-8
              standards-aligned resources and at-home DIY science experiments,
              along with the award-winning annual 3M Young Scientist Challenge,
              the Young Scientist Lab seeks to remove barriers and create
              accessible STEM learning for middle school students.
            </p>
          </Column>
          <Column size={6}>
            <Image filename="image-mission.png" />
            <Link to="/about" className="cta__link no-arrow bg-gradient-orange">
              Read More <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Hands-On Resources */}
    <Section className="home-resources">
      <Container>
        <Row>
          <Column size={10} className="home-resource__item">
            <h2>Hands-on Resources to Inspire Student Scientists</h2>
            <p>
              <strong>
                <em>The world is your laboratory.</em>
              </strong>
              <br />
              <strong>
                Bring science exploration to life wherever learning takes
                place—no laboratory required!
                <br />
                Dive into exciting real-world challenges using flexible digital
                resources designed for in class and beyond.
              </strong>
            </p>
          </Column>
        </Row>
        <Row className="mt-1">
          <Column size={3}>
            <Link to="/science-starters/getting-started">
              <div className="home-resource__item box-shadow nudge-up mr-1">
                <Image
                  filename="icon-desk-classroom-resources.svg"
                  className="home-resource__icon"
                />
                <div className="home-resource__text">
                  <h3>Ready-to-Use Classroom Resources</h3>
                  <h4>Bringing science to every classroom.</h4>
                  <p>
                    Engage students in real-world solution-seeking with
                    standards-aligned activities, lesson plans, digital
                    interactives and more.
                  </p>
                </div>
              </div>
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/science-starters/science-at-home">
              <div className="home-resource__item box-shadow nudge-up mr-1">
                <Image
                  filename="icon-beaker-science.svg"
                  className="home-resource__icon"
                />
                <div className="home-resource__text">
                  <h3>Celebrating DIY Science</h3>
                  <h4>Accessible, at-home science education.</h4>
                  <p>
                    Dive into project-based learning opportunities with
                    life-changing possibilities for students through the
                    nation’s premier middle school science competition.
                  </p>
                </div>
              </div>
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/science-starters/science-at-work">
              <div className="home-resource__item box-shadow nudge-up mr-1">
                <Image
                  filename="icon-molecules.svg"
                  className="home-resource__icon"
                />
                <div className="home-resource__text">
                  <h3>Science at Work</h3>
                  <h4>Experiments & applying science every day.</h4>
                  <p>
                    In a 3M laboratory, scientists come to work every day
                    prepared to research information and explore new
                    possibilities that impact the world and propel innovation to
                    make our world a better place. Read on to learn more about
                    the 3M Scientists!
                  </p>
                </div>
              </div>
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/science-starters/science-at-home">
              <div className="home-resource__item box-shadow nudge-up">
                <Image
                  filename="icon-family-activities.svg"
                  className="home-resource__icon"
                />
                <div className="home-resource__text">
                  <h3>Family Activities</h3>
                  <h4>
                    Science is about ideas. Teaching is about bringing it to
                    life.
                  </h4>
                  <p>
                    Use these free parent activities to turn your home into a
                    lab and let the magic of “doing” begin! Engage your students
                    with topics relevant to their life to underscore the power
                    of science that impacts our world, and more.
                  </p>
                </div>
              </div>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Improving Lives with Science - Entry Topic Carousel */}
    <Section className="home-science">
      <Container>
        <Row className="mt-1">
          <Column size={9}>
            <h2>Change for Good</h2>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <Carousel
              settings={{
                dots: true,
                fade: 1, // Fades between images. Remove this default slide effect.
              }}
              cards={[
                {
                  //image: 'image-slider-improving-health.jpg',
                  image: '3M-YSC-topic-image-robotics.jpg',
                  title: 'Robotics',
                  text: 'Robotics can revolutionize the way we live our everyday lives. These machines, built and programmed to perform tasks without human interference, have made it possible to perform tasks with incredible speed and accuracy. How do you think the power of robotics could impact your community?',
                },
                {
                  //image: 'image-slider-improving-safety.jpg',
                  image: '3M-YSC-topic-image-home.jpg',
                  title: 'Home Improvement',
                  text: 'Science can help us make our homes more energy efficient as well as safer and more comfortable for ourselves and our families. Think of some of the positive changes that could be made to homes through scientific innovation.',
                },
                {
                  //image: 'image-slider-improving-mobility.jpg',
                  image: '3M-YSC-topic-image-auto.jpg',
                  title: 'Automotive',
                  text: 'Cars are essential to many of our lives, as they allow us to move from place to place when public transportation is not an option. Scientists are finding new ways to make the automotive industry more efficient and sustainable while improving car manufacturing and design.',
                },
                {
                  //image: 'image-slider-improving-the-environment.jpg',
                  image: '3M-YSC-topic-image-safety.jpg',
                  title: 'Safety',
                  text: 'Whether you’re on the sports field or crossing the street, safety should always come first. Through science, we can improve safety measures in our everyday lives and help make the things we do and consume safer and cleaner for everyone.',
                },
                {
                  //image: 'image-slider-improving-energy-consumption.jpg',
                  image: '3M-YSC-topic-image-AR-VR.jpg',
                  title: 'AR/VR',
                  text: 'Augmented Reality (AR) and Virtual Reality (VR) are important technologies, because they have the power to change how we live, work, and interact with one another. How can these revolutionary technologies be used for positive change?',
                },
                {
                  //image: 'image-slider-improving-the-community.jpg',
                  image: '3M-YSC-topic-image-climate.jpg',
                  title: 'Climate Tech',
                  text: 'Our planet holds precious resources, and it is our responsibility to properly manage them so future generations can enjoy the same quality of life that we do. As we look to tomorrow, many scientists are harnessing science-based innovations that have the potential to tackle climate-related challenges.',
                },
              ]}
            />
          </Column>
          <Column size={6}>
            <div className="improving-lives">
              <p className="text-24 pb-0.5">
                Great ideas are born from tackling real-world challenges, and
                great things start happening in students’ lives when they apply
                problem-solving skills to issues that matter to them. Empower
                students to tackle important challenges in the 3M Young
                Scientist Challenge with student submissions aligned to one of
                six key areas of impact: Robotics, Home Improvement, Automotive,
                Safety, AR/VR, and Climate Tech.{' '}
              </p>
              <Link
                to="/annual-challenge/entry-topics#health"
                className="cta__link nosticky no-arrow mt-3 bg-gradient-orange"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Student Stories Quote */}
    <Section className="student-stories">
      <StudentQuote
        image="image-moitri-santra-quote.png"
        name="Moitri Santra"
        year="2021 3M Young Scientist Challenge Finalist"
        body="This challenge was a fantastic opportunity to integrate myself more into the
        world of brain computer interfaces and how they can be applied in early diagnosis
        and treatment."
        path="/annual-challenge/finalists-mentors-judges/finalists/moitri-santra"
        classNames="bg-gradient-orange cta__link no-arrow"
        colorbar="orange"
        calloutText="Learn what sparked Moitri’s journey"
      ></StudentQuote>
    </Section>
  </Layout>
);

export default IndexPage;
